import React from "react"
import { graphql } from "gatsby"
import { FixedObject } from "gatsby-image"

import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import PracticeContent from "../../components/PracticeContent"

const name = "Karin van Havere"

type Props = {
  data: DataProps
}

const KarinVanHavere = ({ data }: Props) => {
  return (
    <Layout title={`Fysiotherapie ${name}`}>
      <PageTitle title={`Fysiotherapie ${name}`} />
      <PracticeContent name={name} image={data.file.childImageSharp.fixed}>
        <p>
          In 1990 ben ik afgestudeerd als fysiotherapeut aan de Academie voor
          Fysiotherapie te Heerlen. Sindsdien allerlei opleidingen gevolgd van
          bekkenbodem fysiotherapie tot stressmanagement. Van running
          fysiotherapie tot oedeemfysiotherapie. Door verdiepingscursussen
          gericht op gedragsmatige aanpak bij chronische pijn en copd/diabetes
          beweegprogramma ben ik bij elke cliënt motivational interviewing toe
          gaan passen. Ik vind het een uitdaging om intrinsieke motivatie te
          vinden bij elke patiënt en van hieruit functionele doelen te kunnen
          formuleren als einddoel voor de therapie. In 2014 heb ik de 4 jarige
          masteropleiding tot geriatriefysiotherapeut afgerond. Sinds 2017 ben
          ik lid van Parkinsonnet. Bij de kwetsbare oudere ligt mijn passie. Zo
          lang mogelijk zelfstandig in je eigen omgeving kunnen blijven wonen en
          functioneren. Hierbij zijn korte lijnen met huisarts,
          praktijkondersteuner, mantelzorg, thuiszorg en eventuele andere
          zorgverleners rondom de client zeer belangrijk.
        </p>
        <p>
          Mijn kijk op de mens is holistisch, niet de klacht staat centraal maar
          de persoon met al zijn psychosociale aspecten erbij. Hippocrates :
          “Niet de arts maar het lichaam geneest de ziekte” komt hierdoor in een
          modern jasje.
        </p>
        <p>
          Mijn motto is “oplossings gericht coachen” ik ben uw helpende hand en
          coach. Dit doe ik ook in mijn vrije tijd tijdens het begeleiden van
          start to run groepen bij Atletiek Maastricht. Verder houd ik van
          kokkerellen en wandelen. De mooiste wandeltocht was in 2019 in 2 weken
          wandelen van Porto naar Santiago de Compostella.
        </p>
        <h2>GERIATRIE FYSIO Karin</h2>
        <h3>Waarin ben ik gespecialiseerd?</h3>
        <p>
          Mensen die ouder en minder beweeglijk worden, kunnen daardoor
          belemmeringen of klachten ervaren. Ze komen hiervoor doorgaans in
          aanraking met de fysiotherapeut voor ouderen, de
          geriatriefysiotherapeut. De geriatriefysiotherapeut specialiseert zich
          in de zorg voor kwetsbare ouderen. Hij helpt ook patiënten die
          bijvoorbeeld door een CVA, dementie, de ziekte van Parkinson of een
          gebroken heup verschijnselen vertonen van ouderdom. De patiënten doen
          vooral functionele oefentherapie, gericht op het herwinnen van en
          omgaan met verlies van mobiliteit en zelfstandigheid.
        </p>
        <p>
          Het langer zelfstandig blijven in het dagelijks leven is van groot
          belang en wordt door de huidige wetgeving ook gestimuleerd.De
          behandeling bestaat uit bijvoorbeeld looptraining,
          evenwichtsoefeningen, valpreventie, arm- en handtraining of oefenen
          van het (makkelijker) opstaan uit bed of stoel.
        </p>
        <h3>Samenwerken, plus het streven naar korte lijnen</h3>
        <p>
          Afhankelijk van de hulpvraag wordt er een behandelplan samengesteld.
          Meestal vindt de begeleiding/ behandeling thuis plaats. Hierdoor wordt
          er gestreefd naar een nauwe samenwerking met de thuiszorg en
          behandelend arts. Een multidisciplinaire aanpak is van belang om het
          optimale resultaat te krijgen.
        </p>
        <p>
          Aangezien er in het algemeen dagelijks functioneren, denk aan koken,
          aankleden en boodschappen doen beperkingen kunnen zijn, wordt er nauw
          met een ergotherapeut samengewerkt.
        </p>
        <p>
          Naast de begeleiding van de patiënt speelt ook het geven van advies
          aan mantel- en thuiszorg een grote rol. Bijvoorbeeld over het
          ziektebeeld, de prognose, de transfers ,het uitvoeren van dagelijkse
          handelingen en het aanschaffen van mogelijke (loop)hulpmiddelen.
        </p>
        <p>
          Over eventuele aanpassingen in uw woning kan advies en begeleiding
          voorde aanvraag gegeven worden.
        </p>
        <h3>DE VALEXPERT</h3>
        <p>
          Ik ben opgeleid om valangst training te geven en valpreventie.
          Afhankelijk van uw aanvullende verzekering wordt dit vergoed. Voor
          meer informatie kunt u mij contacteren.
        </p>
        <h3>PARKINSON FYSIOTHERAPIE</h3>
        <p>
          aangesloten bij Parkinsonnet. Om bij uw verzekeraar fysiotherapie
          vergoed te krijgen bij Parkinson moet je aangesloten zijn bij dit
          netwerk.
        </p>
        <h3>BEGELEIDING COPD Patienten</h3>

        <h3>BEWEEGKUUR</h3>
        <p>
          Leefstijlcoaching voor mensen met overgewicht,diabetes,metabool
          syndroom.
        </p>

        <h3>RUNNING FYSIOTHERAPIE</h3>
      </PracticeContent>
    </Layout>
  )
}

export default KarinVanHavere

type DataProps = {
  file: {
    childImageSharp: {
      fixed: FixedObject
    }
  }
}

export const query = graphql`
  {
    file(relativePath: { eq: "karin.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
